import React, { Component } from "react";
import Layout from "../components/layout";
import { graphql } from "gatsby";
import { buildBlocks } from "../services/builder";

class Page extends Component {
  render() {
    const blocks = buildBlocks(
      this.props.data.wpPage.blocks,
      { location: this.props.location },
      {},
      this.props.data.wpPage.id
    );
    return (
      <Layout
        meta={this.props.data.wpPage.Meta}
        path={this.props.pageContext.pagePath}
        cta={this.props.data.wpPage.PageCTA}
        title={this.props.data.wpPage.title}
        themeOptions={this.props.data.allWp.nodes[0].themeOptions}
        globalAlert={this.props.data.allWp.nodes[0].themeOptions.ThemeOptions.globalAlert}
      >
        {blocks}
      </Layout>
    );
  }
}

export const query = graphql`
  query($id: String!) {
    wpPage(id: {eq: $id}) {
        id
        slug
        title
        Meta {
          fieldGroupName
          metaDescription
          metaTitle
          ogDescription
          ogTitle
          twitterCardType
          twitterDescription
          twitterTitle
          twitterImage {
            uri
            sourceUrl
          }
          ogImage {
            uri
            sourceUrl
          }
        }
        PageCTA {
          actionType
          ctaText
          fieldGroupName
          link {
            url
          }
        }
        ... on WpBlockEditorContentNode {
          blocks {
            ...CoreBlock
            ...CoreFields
          }
        }        
      }
      allWp {
        nodes {
          themeOptions {
            accommodationActive
            roomBookingCTAText
            roomBookingImage
            roomBookingImageText
            roomBookingLink
            tableBookingAccountId
            tableBookingActive
            tableBookingBrandId
            tableBookingCTAText
            tableBookingImage
            tableBookingImageText
            tableBookingRestaurantId
            ThemeOptions {
              siteLogo {
                mediaItemUrl
              }
            }
          }
        }
      }
    }
  
`;

export default Page;
